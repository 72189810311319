.header-text {
  text-align: center;
  margin-bottom: 25px;

  h1 {
    font-size: 72px;
    letter-spacing: 5px;
    font-weight: 900;
    margin-top: 25px;
    margin-bottom: 0;
  }

  h2 {
    font-size: 32px;
    font-weight: 300;
    margin-bottom: 25px;
  }

  .main-logo {
    margin-top: 50px;
    margin-bottom: 25px;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .yellow-punch-line {
    color: $color-secondary;
    text-transform: uppercase;
    font-size: 15px;
    margin-bottom: 2px;
  }

  .white-punch-line {
    text-transform: uppercase;
    font-size: 14px;
  }

}

.three-logos {
  position: relative;
  min-height: 300px;
  display: flex;
  margin-bottom: 25px;

  .col.first {
    background-color: transparentize($color-primary, 0.2);
  }
  .col.second {
    background-color: transparentize($color-secondary, 0.2);
  }
  .col.third {
    background-color: transparentize($color-tertiary, 0.2);
  }
  .col {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  img {
    display: inline-block;
    margin: auto;
    max-width: 80%;
    height: auto;
  }

  a {
    display: flex;
    width: calc(100% + 30px);
    height: 100%;
    margin-right: -15px;
    margin-left: -15px;
  }

  @media (max-width: 575px) {
    min-height: 220px;

    .col.first {
      background-color: $color-primary;
    }
    .col.second {
      background-color: $color-secondary;
    }
    .col.third {
      background-color: $color-tertiary;
    }
  }
}

.gim-separator {
  height: 5px;
  margin-top: 20px;
  margin-bottom: 15px;

  .col.first {
    background-color: $color-primary;
  }
  .col.second {
    background-color: $color-secondary;
  }
  .col.third {
    background-color: $color-tertiary;
  }

  &.larger {
    height: 20px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.second-section {
  h2 {
    font-size: 36px;
    font-weight: 900;
    letter-spacing: 2px;
  }

  .sub-title {
    font-size: 14px;
  }

  .products {
    text-transform: uppercase;
    font-size: 24px;

    @media (max-width: 575px) {
      font-size: 18px;
    }
  }

  .notice {
    font-size: 14px;
  }
}

.img-full-width {
  margin-left: -15px;
  margin-right: -15px;

  img {
    width: 100%;
    height: auto;
  }
}