footer {
    padding-top: 40px;

    h3 {
        font-size: 33px;
        margin-bottom: 25px;
    }

    h4 {
        color: $color-secondary;
        font-size: 12px;
        margin-bottom: 10px;
    }

    .logo-footer {
        max-width: 100%;
        margin-bottom: 50px;
    }

    .resp {
        font-size: 12px;

        h5 {
            color: $color-primary;
            margin-bottom: 10px;
            font-size: 12px;
        }

        .resp-name {
            font-weight: 700;
            margin-bottom: 2px;
        }

        .resp-title {
            font-weight: 300;
            margin-bottom: 10px;
        }

        .resp-phone {
            font-weight: 700;
            margin-bottom: 10px;
        }

        .resp-email {
            font-weight: 700;
            margin-bottom: 30px;

            a,
            a:visited {
                color: white;
            }
        }
    }

    .text-ambience {
        max-width: 80%;
        height: 100%;
    }
}

.last-strip {
    height: 39px;
    background-color: $color-primary;
    margin-top: 40px;
}
