.bg-primary {
  background-color: $color-primary !important;
}

.page-nav {
  @media (max-width: 768px) {
    text-align: center;
  }
}

.nav-links {
  display: inline;
  height: 50px;
  padding: 10px;
  list-style-type: none;
  font-size: 10px;

  li {
    display: inline-block;
    color: white;
    padding: 10px 0;

    a {
      display: inline-block;
      padding: 2px 15px;
      border-right: solid white 1px;
      color: white;
      text-decoration: none;
    }

    &:last-child a {
      border: none;
    }
  }
}

