.masonery-title {
    margin-bottom: 25px;

    @media (max-width: 575px) {
        font-size: 18px;
    }
}

.masonery {
    font-weight: 700;
    line-height: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    text-transform: uppercase;

    & > a {
        color: white;
        position: relative;
        display: inline-block;

        &:hover {
            color: white;
        }
    }

    .member {
        width: calc(100% / 7);
        position: relative;
        background-color: $color-primary;
        padding-top: calc(100% / 7);
        cursor: pointer;

        @media (max-width: 767px) {
            width: calc(100% / 5);
            padding-top: calc(100% / 5);
            font-size: 14px;
            line-height: 16px;
        }

        @media (max-width: 575px) {
            width: calc(100% / 4);
            padding-top: calc(100% / 4);
            font-size: 12px;
            line-height: 14px;
        }

        @media (max-width: 399px) {
            font-size: 10px;
            line-height: 12px;
        }

        span {
            color: white;
            display: inline-block;
            position: absolute;
            text-align: center;
            width: 100%;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            padding: 10%;
        }

        &:after {
            content: attr(data-rollover);
            display: none;
            position: absolute;
            width: 100%;
            padding: 10%;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            color: black;
        }

        &:hover,
        &.showAfter{
            span {
                display: none;
            }

            &:before,
            &:after {
                display: block;
            }
        }

        &.primary {
            background-color: $color-primary;
        }
        &.secondary {
            background-color: $color-secondary;
        }
        &.tertiary {
            background-color: $color-tertiary;
        }
    }
}