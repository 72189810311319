html {
  margin-top: 0 !important;
}

body {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  color: white;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-color: black;
}

a {
  color: $link-color;
  text-decoration: underline;

  &:hover {
    color: $link-hover-color;
  }
}

.gim-btn {
  display: inline-block;
  padding: 8px 30px;
  text-align: center;
  color: black;
  margin-bottom: 25px;
  margin-top: 25px;
  line-height: 18px;
  text-transform: uppercase;
  width: 100%;
  text-decoration: none;

  &:hover {
    color: lightgrey;
    text-decoration: none;
  }
}

.btn-primary-color {
  background-color: $color-primary;
}

.btn-secondary-color {
  background-color: $color-secondary;
}

@media (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}

@media (max-width: 575px) {
  .container {
    max-width: 100%;
  }
}